import { render, staticRenderFns } from "./BidConfirmItem.vue?vue&type=template&id=37c6036a&scoped=true"
import script from "./BidConfirmItem.vue?vue&type=script&lang=js"
export * from "./BidConfirmItem.vue?vue&type=script&lang=js"
import style0 from "./BidConfirmItem.vue?vue&type=style&index=0&id=37c6036a&prod&scoped=true&lang=scss"
import style1 from "./BidConfirmItem.vue?vue&type=style&index=1&id=37c6036a&prod&scoped=true&lang=scss"
import style2 from "./BidConfirmItem.vue?vue&type=style&index=2&id=37c6036a&prod&scoped=true&lang=scss"
import style3 from "@/assets/scss/style.scss?vue&type=style&index=3&id=37c6036a&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c6036a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
