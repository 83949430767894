import { render, staticRenderFns } from "./commission.vue?vue&type=template&id=322ff7f6&scoped=true"
var script = {}
import style0 from "@/assets/scss/style.scss?vue&type=style&index=0&id=322ff7f6&prod&scoped=true&lang=scss&external"
import style1 from "@/assets/scss/other.sass?vue&type=style&index=1&id=322ff7f6&prod&scoped=true&lang=sass&external"
import style2 from "./commission.vue?vue&type=style&index=2&id=322ff7f6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "322ff7f6",
  null
  
)

export default component.exports