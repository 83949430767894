import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=630a845c&scoped=true"
var script = {}
import style0 from "@/assets/scss/style.scss?vue&type=style&index=0&id=630a845c&prod&scoped=true&lang=scss&external"
import style1 from "@/assets/scss/guide.sass?vue&type=style&index=1&id=630a845c&prod&scoped=true&lang=sass&external"
import style2 from "@/assets/scss/other.sass?vue&type=style&index=2&id=630a845c&prod&scoped=true&lang=sass&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630a845c",
  null
  
)

export default component.exports