import { render, staticRenderFns } from "./Faq.vue?vue&type=template&id=7e73065f&scoped=true"
var script = {}
import style0 from "@/assets/scss/style.scss?vue&type=style&index=0&id=7e73065f&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e73065f",
  null
  
)

export default component.exports