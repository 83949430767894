<template>
  <body>
    <header>
      <div class="wrap1160N">
        <div class="h-top">
          <h1 class="h-top-logo topLogo">
            <a class="logo" href="/">
              <img
                src="./img/common/logo.png"
                alt="ライマ"
              />
            </a>
            <p class="fontB">バイクのオークション<br class="only_sp">「ライダーズマーケット」</p>
          </h1>
        </div>
      </div>
    </header>
    <main id="main">
      <section>
        <div class="wrap1160 informationWrap">
          <div class="information">
            <h2>リニューアル準備中</h2>
            <p class="text">日頃よりライダーズマーケットをご利用いただき誠にありがとうございます。<br>只今サイトリニューアルに向けて準備中です。<br>この度、サイトのメンテナンスを実施し<br class="only_pc">より快適にご利用いただけるようパワーアップいたします。<br>ご不便、ご迷惑をおかけして申し訳ございません。<br>オープン再開のご案内は別途ご案内いたします。<br>どうぞ、新しいライダーズマーケットにご期待くださいませ<br class="only_pc">今後ともよろしくお願いいたします。</p>
            <p class="text">ライダーズマーケット運営事務局</p>
          </div>
        </div>
      </section>
    </main>
    <footer>
      <p class="copyright"><small>Copyright © 2023 All rights reserved</small></p>
    </footer>
</body>
</template>

<script>
export default {
  name : 'Maintenance'
}
</script>

<style scoped>
@import "./assets/css/maintenance.css";
</style>
