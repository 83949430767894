import {
  getCurrentInstance
} from '@vue/composition-api'

/**
 * 現在のインスタンスを取得する
 * setup関数内で呼ばれているかの確認のためにこのメソッドをかませる
 * @returns インスタンス
 */
const getInstance = () => {
  const instance = getCurrentInstance()

  if (!instance) {
    throw new Error('Should be used in setup()')
  }

  return instance
}

/**
 * 4.x系で使えるuseRouterの代替メソッド
 * setup()関数内でのみ使用可能
 * @returns Routerオブジェクト
 */
export const useRouter = () => {
  // インスタンスにアクセス
  const instance = getInstance()

  // Proxyが従来の`this`にあたるコンポーネントインスタンス
  return instance.proxy.$router
}

/**
 * 4.x系で使えるuseRouteの代替メソッド
 * setup()関数内のみで使用可能
 * @returns Routeオブジェクト
 */
export const useRoute = () => {
  // インスタンスにアクセス
  const instance = getInstance()

  // Proxyが従来の`this`にあたるコンポーネントインスタンス
  return instance.proxy.$route
}

export const routerGo = (router, name, id = null, query = {}, hash = null) => {
  const obj = {
    name,
    query
  }
  if (id) {
    obj.params = {id}
  }
  if (hash) {
    obj.hash = `#${hash}`
  }
  router.push(obj).catch(() => {})
}
