import Base from '@/common/base'
import i18n from '@/language/i18n'
import _ from 'lodash'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
import {routerGo} from './useRouter'
import useSetting from './useSetting'

const loadingStore = useLoading()

const limitPerPages = [50, 100]
const initFilter        = {
  favorite            : null,
  startArea           : null,
  currentPriceSort    : null,
  areas               : [],
  category            : [],
  categoryExtension   : [],
  maker               : null,
  body_type           : null,
  bike_type           : null,
  startCubicCapacity  : null,
  endCubicCapacity    : null,
  startMileage        : null,
  endMileage          : null,
  limit               : limitPerPages[0],
  initLimit           : limitPerPages[0]
}

const useItemSearch = defineStore('useItemSearch', {
  state : () => {
    return {
      limitPerPages,
      errors                : [],
      constants             : {},
      items                 : [],
      filter                : _.cloneDeep(initFilter),
      filteredObject        : {},
      areaConstants         : [],
      categoryConstants     : [],
      autoPitchConstants    : [],
      taxRateConstants      : [],
      craneCategoryIds      : [],
      isMoreLimit           : false,
      itemsCount            : 0,
    }
  },
  actions : {
    getItemSearchConstants(router, locale = 'ja') {
      loadingStore.setLoading(true)
      return Methods.apiExcute('public/get-item-search-constants', locale)
        .then(data => {
          loadingStore.setLoading(false)
          // User setting
          const settingStore = useSetting()
          settingStore.setSkipBidConfirm(!!(data.user_setting && data.user_setting.skip_bid_confirm))
          settingStore.setHasCard(!!(data.user_setting && data.user_setting.has_card))

          // Constants
          this.constants = {}
          if (data && data.constants) {
            data.constants.forEach(row => {
              switch (row.key_string) {
              case 'SELECT_MAKER':
                if (!this.constants[row.key_string]) {
                  this.constants[row.key_string] = []
                }
                if (row.value3) {
                  this.constants[row.key_string].push({
                    header      : row.value3,
                    divider     : true,
                  })
                }
                this.constants[row.key_string].push({
                  value       : Number(row.value1),
                  label       : row.value2,
                })
                break
              case 'DOCUMENT_FILES':
              case 'SELECT_YES_NO':
              case 'SELECT_FUEL_TYPE':
              case 'SELECT_BATTERY_HEALTH':
              case 'SELECT_RADIATOR_LEAK':
              case 'SELECT_ARI_NASI':
              case 'SELECT_TEXT_AREA':
              case 'SELECT_BODY_TYPE':
              case 'SELECT_IDENTITY_FILE':
              case 'SELECT_INPUT_OPTION':
              case 'PREFECTURES_CODE':
              case 'SELECT_FUMEI_NASI':
              case 'SELECT_YES_NO_TACO':
                if (!this.constants[row.key_string]) {
                  this.constants[row.key_string] = []
                }
                this.constants[row.key_string].push({
                  value      : row.value1,
                  label      : row.value2,
                  isTxtInput : row.value3
                })
                break
              default:
                break
              }
            })

            this.autoPitchConstants = data.constants.filter(constant => constant.key_string === 'PITCH_FOLLOW_BID_PRICE')
            this.taxRateConstants = data.constants.filter(constant => constant.key_string === 'TAX_RATE')
          }
          return this.initSearch(router)
        })
    },
    initSearch(router) {
      this.filter.areas = []
      this.filter.category = []
      this.filter.categoryExtension = []
      return this.searchItems(router)
    },
    updateViewAfterBid(bidList) {
      console.log('updateViewAfterBid')
      // This.showBulkBiddingButton = false
      this.items.map(item => {
        const bid = bidList.find(bid => String(item.exhibition_item_no) === String(bid.exhibition_item_no))
        if (bid) {
          item.bid_status = Object.assign({}, item.bid_status, bid)
          if (this.removeBulkItemByItemNo) {
            this.removeBulkItemByItemNo(item.exhibition_item_no)
          }
          item.attention_info.bid_count += 1
        }
        if (item.changed) {
          // This.showBulkBiddingButton = true
        }
        return item
      })
    },
    searchAuctionItems(locale, params) {
      return Methods.apiExcute('public/search-auction-items', locale, Object.assign({exhClass : 1}, params))
    },
    searchItems(router, showedItemNos = null, locale = 'ja') {
      loadingStore.setLoading(true)
      const params = this.getSearchItemsRequestParams(this.filter, showedItemNos)
      return this.searchAuctionItems(locale, params)
        .then(data => {
          loadingStore.setLoading(false)
          this.isMoreLimit = data.isMoreLimit
          this.itemsCount = data.count
          if (data.moreSearch) {
            Array.prototype.push.apply(this.items, data.items.map(x => {
              const makerName = ((this.constants.SELECT_MAKER || []).find(y => String(y.value) === String(x.free_field.maker)) || {}).label
              return {
                ...x,
                free_field : {...x.free_field, makerName}
              }
            }))
            this.filter.initLimit = this.items.length
          } else {
            this.items = data.items.map(x => {
              const makerName = ((this.constants.SELECT_MAKER || []).find(y => String(y.value) === String(x.free_field.maker)) || {}).label
              return {
                ...x,
                free_field : {...x.free_field, makerName}
              }
            })
          }
          return Promise.resolve()
        })
        .then(() => {
          this.filteredObject = JSON.parse(JSON.stringify(this.filter))
          if (router.currentRoute.meta.enableQuery) {
            routerGo(router, router.currentRoute.name, null, {
              filter : encodeURIComponent(JSON.stringify(this.filter))
            })
          }
          return Promise.resolve()
        })
    },
    getFiltered(filter, typeTag) {
      const filtered = []
      filtered.push(i18n.t(typeTag))
      if (filter.areas) {
        filter.areas.forEach((element, index) => {
          if (element) {
            filtered.push(this.areaConstants[index].value2)
          }
        })
      }
      if (filter.category) {
        filter.category.forEach((element, index) => {
          if (element) {
            filtered.push(this.categoryConstants[index].value2)
            filter.categoryExtension[index].forEach(row => {
              filtered.push(row)
            })
          }
        })
      }
      if (filter.searchKey) {
        filtered.push(filter.searchKey)
      }
      if (filter.startYear || filter.endYear) {
        filtered.push(`${filter.startYear || ''}～${filter.endYear || ''}`)
      }
      if (filter.startPrice || filter.endPrice) {
        filtered.push(`${filter.startPrice ? filter.startPrice + i18n.t('ITEM_SEARCH_PRICE_UNIT') : ''}～${filter.endPrice ? filter.endPrice + i18n.t('ITEM_SEARCH_PRICE_UNIT') : ''}`)
      }

      if (filter.favorite) {
        filtered.push(i18n.t('ITEM_SEARCH_FAVORITE_LABEL'))
      }
      if (filter.bidding) {
        filtered.push(i18n.t('ITEM_SEARCH_BIDDING_LABEL'))
      }
      if (filter.exceedingLowestPrice) {
        filtered.push(i18n.t('ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL'))
      }
      if (filter.soldOut) {
        filtered.push(i18n.t('ITEM_SEARCH_SOLD_OUT_LABEL'))
      }
      if (filter.recommending) {
        filtered.push(i18n.t('ITEM_SEARCH_RECOMMEND_LABEL'))
      }
      if (filter.newStock) {
        filtered.push(i18n.t('ITEM_SEARCH_NEW_LABEL'))
      }
      return filtered
    },
    getSearchItemsRequestParams(filter, showedItemNos = null) {
      const params = Object.assign({}, filter, {
        showedItemNos,
        areas         : filter.areas.map((area, index) => area ? this.areaConstants[index].value1 : null).filter(row => row),
        startPrice    : Number(filter.startPrice) * 10000,
        endPrice      : Number(filter.endPrice) * 10000,
        startArea     : this.filter.startArea
          ? ((this.constants.PREFECTURES_CODE || []).find(x => x.value === this.filter.startArea) || {}).label
          : null,
        // Maker         : this.filter.maker,
        body_types    : this.filter.body_type ? [this.filter.body_type] : null,

        limit         : showedItemNos ? filter.limit : filter.initLimit,
        category      : filter.category
          .map((row, index) => {
            if (row) {
              const array = this.filter.categoryExtension[index].map(ext => {
                return {
                  categoryNo : this.categoryConstants[index].value1,
                  extension  : ext
                }
              })
              return array.length > 0
                ? array
                : [
                  {
                    categoryNo : this.categoryConstants[index].value1
                  }
                ]
            } else {
              return null
            }
          })
          .filter(row => row)
          .reduce((a, b) => {
            const tmp = []
            Array.prototype.push.apply(tmp, a)
            Array.prototype.push.apply(tmp, b)
            return tmp
          }, [])
      })
      delete params.categoryExtension
      return params
    },
    getPriceIncludeTax(price, tax_rate = null) {
      const tax = tax_rate || (this.taxRateConstants ? this.taxRateConstants[0].value1 : 0)
      const price_s2n = Base.localeString2Number(price)
      const taxed = price_s2n + (price_s2n * tax / 100)
      return taxed.numberString()
    },
    updateFilter(router, newFilter) {
      if (typeof newFilter.category === 'undefined') {
        this.filter = Object.assign({}, initFilter, newFilter)
        this.initSearch(router)
      }
    },
    initFilter() {
      this.filter = _.cloneDeep(initFilter)
    },
    getInspectionTxt(optionId, inputTxt) {
      if (this.constants.SELECT_FUMEI_NASI) {
        const fumeiNasi = this.constants.SELECT_FUMEI_NASI.find(x => x.value === String(optionId)) || {}
        return fumeiNasi.isTxtInput === '1' ? inputTxt : fumeiNasi.label || ''
      }
      return ''
    }
  },
  getters : {
  },
})

export default useItemSearch
