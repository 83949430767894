import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=576b5438&scoped=true"
import script from "./Detail.vue?vue&type=script&lang=js"
export * from "./Detail.vue?vue&type=script&lang=js"
import style0 from "./Detail.vue?vue&type=style&index=0&id=576b5438&prod&scoped=true&lang=scss"
import style1 from "./Detail.vue?vue&type=style&index=1&id=576b5438&prod&scoped=true&lang=scss"
import style2 from "./Detail.vue?vue&type=style&index=2&id=576b5438&prod&scoped=true&lang=scss"
import style3 from "./Detail.vue?vue&type=style&index=3&id=576b5438&prod&scoped=true&lang=scss"
import style4 from "./Detail.vue?vue&type=style&index=4&id=576b5438&prod&scoped=true&lang=scss"
import style5 from "./Detail.vue?vue&type=style&index=5&id=576b5438&prod&scoped=true&lang=scss"
import style6 from "./Detail.vue?vue&type=style&index=6&id=576b5438&prod&scoped=true&lang=scss"
import style7 from "./Detail.vue?vue&type=style&index=7&id=576b5438&prod&scoped=true&lang=scss"
import style8 from "./Detail.vue?vue&type=style&index=8&id=576b5438&prod&scoped=true&lang=scss"
import style9 from "@/assets/scss/style.scss?vue&type=style&index=9&id=576b5438&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576b5438",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
